@import "styles/core.scss";
/**
 * Generated file
 * Do not edit directly
 */

[data-at-theme-dimensions='compact'] {
  --space-1200: 4rem;
  --space-800: 3rem;
  --space-600: 2.25rem;
  --space-450: 1.5rem;
  --space-300: 1rem;
  --space-200: 0.75rem;
  --space-150: 0.5rem;
  --space-128: 8rem; /* deprecate */
  --space-100: 0.25rem;
  --space-96: 6rem; /* deprecate */
  --space-64: 4rem; /* deprecate */
  --space-50: 0.125rem;
  --space-48: 3rem; /* deprecate */
  --space-40: 2.5rem; /* deprecate */
  --space-32: 2rem; /* deprecate */
  --space-24: 1.5rem; /* deprecate */
  --space-16: 1rem; /* deprecate */
  --space-12: 0.75rem; /* deprecate */
  --space-8: 0.5rem; /* deprecate */
  --space-4: 0.25rem; /* deprecate */
  --space-0: 0.25rem; /* deprecate */
  --size-header-height-mobile: 4rem;
  --size-header-height-desktop: 4rem;
  --size-controls-lg: 3rem;
  --size-controls-md: 2.25rem;
  --size-controls-sm: 1.75rem;
  --size-icon-lg: 1.5rem;
  --size-icon-md: 1.25rem;
  --size-icon-sm: 1rem;
  --size-icon-xs: 0.75rem;
  --shadow-300: 0px 8px 12px 0px rgba(0, 0, 0, 0.15), 0px 0px 1px 0px rgba(0, 0, 0, 0.3);
  --shadow-250: 0px 0px 8px 0px rgba(0, 0, 0, 0.16), 0px 0px 1px 0px rgba(0, 0, 0, 0.12);
  --shadow-200: 0px 4px 8px 0px rgba(0, 0, 0, 0.18), 0px 0px 1px 0px rgba(0, 0, 0, 0.3);
  --shadow-100: 0px 2px 4px 0px rgba(0, 0, 0, 0.14), 0px 0px 1px 0px rgba(0, 0, 0, 0.3);
  --radius-round: 624.9375rem;
  --radius-xl: 1rem;
  --radius-lg: 0.5rem;
  --radius-md: 0.25rem;
  --radius-sm: 0.125rem;
  --radius-none: 0;
  --font-letterspacing-400-responsive: -0.03125rem;
  --font-letterspacing-500-responsive: -0.03125rem;
  --font-letterspacing-600-responsive: -0.0625rem;
  --font-letterspacing-700-responsive: -0.0625rem;
  --font-letterspacing-700: -0.0625rem;
  --font-letterspacing-600: -0.03125rem;
  --font-letterspacing-500: -0.03125rem;
  --font-letterspacing-400: -0.015625rem;
  --font-size-700-responsive: 3.75rem;
  --font-size-600-responsive: 2.25rem;
  --font-size-500-responsive: 2rem;
  --font-size-400-responsive: 1.75rem;
  --font-size-700: 2.75rem;
  --font-size-600: 1.875rem;
  --font-size-500: 1.5rem;
  --font-size-400: 1.25rem;
  --font-size-300: 1rem;
  --font-size-200: 0.875rem;
  --font-size-100: 0.75rem;
  --font-size-50: 0.5625rem;
  --font-lineheight-loose: 1.7;
  --font-lineheight-default: 1.4;
  --font-lineheight-400-responsive: 1.2;
  --font-lineheight-500-responsive: 1.15;
  --font-lineheight-600-responsive: 1.1;
  --font-lineheight-700-responsive: 1.06;
  --font-lineheight-700: 1.1;
  --font-lineheight-600: 1.15;
  --font-lineheight-500: 1.18;
  --font-lineheight-400: 1.2;
  --font-weight-bold: bold;
  --font-weight-normal: normal;
  --font-family-secondary: Beatrice;
  --font-family-primary: Manrope;
  --borders-weight-lg: 0.125rem;
  --borders-weight-md: 0.09375rem;
  --borders-weight-sm: 0.0625rem;
  --timing-linear: cubic-bezier(0, 0, 1, 1);
  --timing-ease-out: cubic-bezier(0.32, 0.94, 0.6, 1);
  --timing-ease-in: cubic-bezier(0.15, 0, 0.15, 0);
  --duration-long: 2000ms;
  --duration-default: 500ms;
  --duration-short: 300ms;
  --z-index-toast: 1000;
  --z-index-spinner: 900;
  --z-index-popover: 800;
  --z-index-modal: 400;
  --z-index-overlay: 300;
  --z-index-dropdown: 200;
  --z-index-sticky: 100;
  --z-index-default: 1;
  --z-index-deep: -99999;
  --breakpoint-lg-margin: 5rem;
  --breakpoint-lg-size: 120rem;
  --breakpoint-md-margin: 3rem;
  --breakpoint-md-size: 70rem;
  --breakpoint-sm-margin: 1.5rem;
  --breakpoint-sm-size: 47.5rem;
  --breakpoint-xs-margin: 1rem;
  --breakpoint-xs-size: 22.5rem;
  --grid-page-max-width: 80rem;
  --space-page-horizontal-mobile: var(--space-300);
  --space-horizontal-screen-mobile: var(--space-300);
  --size-button-radius-lg: var(--radius-lg);
  --size-button-radius-md: var(--radius-md);
  --size-button-radius-sm: var(--radius-md);
  --radius-card: var(--radius-lg);
  --radius-sheet: var(--radius-lg);
}
