@import "styles/core.scss";
@import '@alltrails/denali/styles/colors-light.css';
@import '@alltrails/denali/styles/colors-dark.css';
@import '@alltrails/denali/styles/colors-admin-light.css';
@import '@alltrails/denali/styles/colors-admin-dark.css';
@import '@alltrails/denali/styles/dimensions-default.css';
@import '@alltrails/denali/styles/dimensions-compact.css';
@import './osano.scss';

html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

html.modalOpen {
  overflow: hidden;
  // Prevents "pull to refresh" on mobile web.
  overscroll-behavior: none;

  header {
    position: relative;
    z-index: 0;
  }
}

html,
body {
  padding: 0;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  scroll-behavior: smooth;
}

ul,
li {
  list-style-type: none;
  padding: 0px;
  margin: 0px;
}

a {
  text-decoration: none;
}

@font-face {
  font-family: Beatrice;
  src: url('https://cdn-assets.alltrails.com/assets/beatrice-medium-b7819fd439aa766db7c78485a992a47d9a518f96a7eb025575483869631827e7.otf');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Manrope;
  src: url('https://cdn-assets.alltrails.com/assets/manrope-medium-d2f4412e022ab72dc167a99a24778271f6be77839bc850f64b97935ab389e069.otf');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Manrope;
  src: url('https://cdn-assets.alltrails.com/assets/manrope-bold-b172ea7bae0d5e36c42c4c5b7c500d712a90089f9f16da5e6b0d701f45dcb005.otf');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Percent;
  src: url('./manrope-medium.woff2');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
  unicode-range: U+25;
}

@font-face {
  font-family: Percent;
  src: url('./manrope-bold.woff2');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
  unicode-range: U+25;
}
